html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}