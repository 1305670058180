.react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.react-pdf__Page {
  margin: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.react-pdf__Page__textContent ::selection{
  background-color: #0044B4;
}

.textLayer :is(span, br) {
  cursor: inherit;
}

.highlightMode .react-pdf__Page__textContent ::selection{
  background-color: transparent;
}

.react-pdf__Page__textContent  {
  color: transparent;
  opacity: 0.5;
}

/* .react-pdf__Page__textContent ::selection {
  background-color: #0044B4;
} */