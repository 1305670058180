.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: #EDEFF3; /* bg/gray */
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

//styles for scrollbar
::-webkit-scrollbar {
  width: 13px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #7890B2;  /* secondary/gray */
  border-radius: 9px 8px 8px 9px;
  border-width: 2px 2px 2px 3px;
  border-style: solid;
  border-color: transparent;
  background-clip: content-box;

  &:hover {
    background-color: #849ec3;
  }
}

::-webkit-scrollbar-corner {
  display: none
}

@supports (-moz-appearance:none) {
  *, *::before, *::after {
    scrollbar-color: #7890B2 transparent;  /* secondary/gray */
    scrollbar-width: thin;
  }
}